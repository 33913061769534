import * as React from "react"
import Layout from "../components/layout"
import styled from "@emotion/styled"
import { graphql } from "gatsby"

const Content = styled.div`
  max-width: 42rem;
  margin: 0 auto;
  padding: 1.5rem;
  position: static;
  padding-top: 2rem;
`

interface PrivacyProps {
  readonly data: PrivacyData
}

export default class PrivacyPage extends React.Component<PrivacyProps> {
  public render() {
    const { data } = this.props
    const pp = data.allMarkdownRemark.edges[0]

    return (
      <Layout>
        <Content>
          <h3>{pp.node.frontmatter.title}</h3>
          <div dangerouslySetInnerHTML={{ __html: pp.node.html }} />
        </Content>
      </Layout>
    )
  }
}

interface PrivacyData {
  allMarkdownRemark: {
    edges: {
      node: {
        html: string
        frontmatter: {
          title: string
        }
      }
    }[]
  }
}

export const query = graphql`
  query {
    allMarkdownRemark(
      filter: { frontmatter: { slug: { eq: "privacy-policy" } } }
    ) {
      edges {
        node {
          html
          frontmatter {
            title
          }
        }
      }
    }
  }
`
